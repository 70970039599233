import * as React from 'react';
import { IK3ProductCompositionTable, K3ProductCompositionTableExtensionClass } from '@k3-msdyn365-commerce-modules/k3.msdyn365.commerce.online/dist/types/actions/DataServiceEntities.g';
import { IK3CompositionsViewProps } from '@k3-msdyn365-commerce-modules/k3.msdyn365.commerce.online/dist/types/modules/k3-compositions/k3-compositions';
import { Drawer } from '@msdyn365-commerce-modules/utilities';

export default (props: IK3CompositionsViewProps) => {
    const {
        data: { productCompositions, compositionTypes, fibres },
        productRecId
    } = props;

    let open = true;

    const [compositionState, setCompositionState] = React.useState<IK3ProductCompositionTable[] | undefined>(undefined);

    const _toggle = () => (): void => {
        open = !open;
    };

    const [hasSetValue, setHasSetValue] = React.useState<boolean>(false);

    if (!hasSetValue) {
        const renderModule =
            productRecId && !!productCompositions.result?.filter(composition => composition.Product === productRecId).length;

        if (renderModule) {
            const thatOne = productCompositions.result?.filter(
                composition => composition.Product === productRecId && composition.ItemDataAreaId === ''
            );
            if (thatOne && thatOne.length > 0) {
                setCompositionState(thatOne);
                setHasSetValue(true);
            }
        }
    }

    if (compositionState && compositionState.length) {
        const compositionMap = new Map();
        compositionState.forEach(cs => {
            const collection = compositionMap.get(cs.CompositionType);
            if (!collection) {
                compositionMap.set(cs.CompositionType, [cs]);
            } else {
                collection.push(cs);
            }
        });
        // console.log(compositionState);
        return (
            <Drawer
                key={'materialslot'}
                collapseProps={{
                    timeout: 0,
                    isOpen: true
                }}
                className='ms-product-specification__drawer'
                openGlyph='ms-product-specification__drawer-open'
                closeGlyph='ms-product-specification__drawer-close'
                glyphPlacement='end'
                toggleButtonText='Material'
                onToggle={_toggle}
            >
                <div className='ms-product-details'>
                    <span className='compositions'>
                        {[...compositionMap.values()].map((cm: K3ProductCompositionTableExtensionClass[]) => (
                            <p key={cm[0].CompositionType}>
                                {`${compositionTypes?.result?.find(el => el.RecId === cm[0].CompositionType)?.Description}: ` || ''}

                                {cm.map((c: K3ProductCompositionTableExtensionClass) => (
                                    <span className='fibres' key={c.Fibre}>
                                        {`${c.Percent}% ${fibres?.result?.find(el => el.RecId === c.Fibre)?.Description}` || ''}
                                        <span className='comma'>, </span>
                                    </span>
                                ))}
                            </p>
                        ))}
                    </span>
                </div>
            </Drawer>
        );
    } else {
        return null;
    }
};
